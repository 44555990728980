import React, {  } from 'react';
import SiteLayoutEmbeded from '../../components/SiteLayoutEmbeded';
import { IdentifiserComponent } from '../identifiser';

function Index() {
    return (
        <SiteLayoutEmbeded>
            <IdentifiserComponent isEmbeded={true} />
        </SiteLayoutEmbeded>
    );
}

export default Index;
